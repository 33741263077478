.tableCellLeft {
  padding-left: 0px !important;
}

.tableRowRight {
  padding-right: 0px;
}

.closeIcon {
  div {
    background-color: #eee !important;
  }
}

.table {
  td {
    padding: 8px 0px !important;
    height: 24px !important;
  }
}

.iconWrapper {
  border-radius: 50%;
  height: 22px;
  width: 22px;
  display: inline-block;
  padding: 3px;
  position: relative;
  top: 5px;
  svg {
    display: block;
    margin: 0px;
  }
}

.positive {
  background-color: #E1F2E6;
}

.informative {
  background-color: #D9E8FE;
}

.negative {
  background-color: #FFF4F5;
}