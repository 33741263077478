

$gray-88: #e0e0e0;
$gray-94: #f0f0f0;


  @keyframes shimmer {
    0% {
      background-position: 100% 0;
    }

    100% {
      background-position: -100% 0;
    }
  }

  .Skeleton:empty {
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    color: transparent !important;
    background: $gray-94;
    background: linear-gradient(to right, $gray-94 8%, $gray-88 18%, $gray-94 33%);
    background-position: 0 0;
    background-size: 200% 100%;
    border-radius: 0.4rem;
    height: 1.5rem;
  }

  .Skeleton::before {
    content: "_";
  }


  // content loading

  .Card {
    padding: 2rem;
  }

  .Content {
    width: 65%;
    display: flex;
    flex-direction: column;
  }

  .Content > div {
    margin-bottom: 1.25rem;
  }

  .Line1 {
    width: 100%;
  }

  .Line2 {
    width: 85%;
    margin-left: 10%;
  }

  .Line3 {
    width: 65%;
    margin-left: 10%;
  }

  .Line4 {
    width: 100%;
  }

  .Line5 {
    width: 75%;
    margin-left: 10%;
  }

  .Line6 {
    width: 30%;
    margin-left: 10%;
  }

  .Line2Small {
    width: 75%;
    margin-left: 10%;
  }

  .Line3Small {
    width: 100%;
    margin-left: 10%;
  }

  .Line4Small {
    width: 50%;
    margin-left: 10%;
  }


