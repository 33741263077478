@import "../../../../../node_modules/@lmig/liberty-ui-tokens/scss/palette.scss";

.verticalLine {
  display: block;
  border-left: 0.125em solid $lm-color-palette-gray-10;
  height: 100%;
}

.dividerCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
