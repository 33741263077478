
.lmFieldGroup {
	margin:0;
}


.status {
	color:green;
}

.lmig-Modal{
	overflow: scroll;
	height: 100vh;
}

.lmigModalbody{
	
	background:white;
	
	tbody {
		font-size:14px;
		line-height:10px;
	}
	h3{
		margin:0;
	}

	h3 {
		font-size:20px;
	} 
	h5 {
		font-size:16px;
	}
	h6{
		font-size:14px;
	}
	margin:0;
}


.tableScroll {
	width: 90vw;
}


.table {
	tr { 
		position: relative;
		th {			
			background:white;
		}
		td{ 
			position: absolute;
			right:0;
			top:10%;
			border: none;
		}
	}
}