@import "node_modules/@lmig/liberty-ui-tokens/scss/palette";

.results {
  background:#F5F5F5;
  height: 100%;

  hr {
    margin: 3px;
  }
  .lm-Card-children{
    border:1px solid red;
  }
}

.lmFieldGroup {
  margin-top: 0;
}

.searchcard {
  margin: 0%;
  height:100%;
}
.borderbottomblack {
  border-bottom: 1.5px solid  black;
}
.borderbottom {
  border-bottom: 1px solid  #D4D4D4;
}

.liabilitystatustxt {
  color: green;
}


.exitIcon {
  width: 16px;
  height: 16px;
  margin-left: 5px;
  path {
    fill: #06748c;
  }
}
