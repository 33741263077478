.shopName {
    margin-top: 0px;
    margin-bottom: 0px;
}

.fieldGroup {   
    margin: 1.5rem 0;
}

.lmCard {
  border: none;
}

.termsAndCondTxt {
  padding: 1rem 0 0;
}