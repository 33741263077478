.box {
	background:#eee;
	position:absolute;
	width: 100%;
	left:0;
	margin-top:0rem;
	z-index: -1;	

	&.mobileBox{
		height:19rem;
	}

	&.desktopBox{
		height:22rem;
	}
}




