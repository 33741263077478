@import "../../../../../node_modules/@lmig/liberty-ui-tokens/scss/palette";
@import "../../../../../node_modules/@lmig/liberty-ui-tokens/scss/breakpoint";

.footer {

  li {
    display: inline-block;
     margin-right: 0.25rem;
  }

  li:after {
    content: " | ";
    color: white;
  }

  li:last-child:after {
    content: none;
  }

  ul {
    padding-left: 0;
  }

  img {
    margin-top: 1rem;
  }

  p {
    padding-top: 0.125rem;
    color: white;
  }

  .imageWrapper {
    text-align: center;
  }

}

@media only screen and (max-width: $lm-breakpoint-sm-max) {
  .footer {
    text-align: center;
  }
}
