@import "~@lmig/lmds-react/base";
@import "node_modules/@lmig/liberty-ui-tokens/scss/palette";


.cu-line-wrap {
  overflow-wrap: anywhere;
}

.cu-pull-right {
  float: right;
}

.cu-hr {
  border-top: 1px solid $lm-color-palette-gray-10;
}

.cu-cache-disable {
  color: red;
}
