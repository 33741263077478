.claimSearchPage {     
    .fieldGroup {   
        margin: 1.5rem 0;
    }
    .desktop{
        padding: 2.2rem 1rem
    }

    .cell{
        display:flex;
        justify-content: space-between;
        span{
            text-align: right;
        }
    }

}

.status div {
    overflow-wrap: anywhere;
}
.lmFieldGroup {
    margin:0;
    & > div{
        margin:0;
    }
}

.lmigModalbody{
    margin:0;
    span{
        line-height:1.5rem;
    }
}

.tabScroll {
    width: 90vw !important;
    max-width:1200px;
}

.card {
    height: 100%;
}

.wordBreak {
	word-break: break-all;
}

.icon {
    display:flex;
    align-items: center;
    padding-bottom: .5em;
    span{
        padding-left:.5em;
    }
}