.toggleButton {
  [class=lmig-FieldOption-tick] {
    display: none;
  }

  ;

  [class=lmig-FieldOption-label] {
    display: block;
    text-align: center !important;
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
  }

  ;

  [class=lmig-FieldOption-container] {
    border: 1px solid #919191;
    height: 36px !important;
    padding: 1rem 1rem;
    position: relative;
    display: -ms-flexbox;
    display: flex !important;
  }

  white-space: nowrap;
}

.borderLeft {
  [class=lmig-FieldOption-container] {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.borderRight {
  [class=lmig-FieldOption-container] {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0 !important
  }
}

.newLine {
  display: block;
  border-top: solid 1px gray;
  width: 100%;
  height: 1px;
  top: 50%;
  z-index: 1;
}