@import "../../../../../../node_modules/@lmig/liberty-ui-tokens/scss/breakpoint";

.logoutBtn {
  cursor: pointer;
  background-color: transparent;
  border: none;
  text-align: center;

  .logoutLogo {
    height: 32px;
    width: 32px;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    padding: 6px 8px 10px 8px;
    margin: 5px;
  }

  @media (max-width: $lm-breakpoint-base-max) {

    .logoutText {
      display: none;
    }

  }
}
