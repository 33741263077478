@import "../../../../../node_modules/@lmig/liberty-ui-tokens/scss/spacing";
@import "../../../../../node_modules/@lmig/liberty-ui-tokens/scss/color";

.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $lm-spacing-default-between;
  background-color: $lm-color-header-background;
}

.notification {
  margin: 0 auto;
}